






// @ is an alias to /src
import { Component, Vue, Provide } from "vue-property-decorator";
import { State, Getter, Mutation, Action } from "vuex-class";

@Component({
  components: {  },
})
export default class Index extends Vue {
  // 配置信息
  @Action("setConfig") setConfig: any;
  created() {
    this.getSystemConfig();
  }
  mounted(){
  }
  handleScroll(){
  }
  // 跳转到商品详细页面
  toGoods(goods_id:any): void {
    // let p:o = ;
    // this.$router.push({ name: 'goods', params: { id: goods_id }});
    this.$router.push({ name: 'goods', query: { id: goods_id }});
  }
  // 跳转分类商品列表页
  toCat(cat_id:any): void {
    this.$router.push({ name: 'categoryList', query: { cat_id: cat_id }});
  }

  // 获取配置信息
  getSystemConfig() {
    let forms = {
        m: "base.getSystemConfig",
        cat_id: 1
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          let config = res.data.data.config;
          this.setConfig(config);

          var link = document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          link.href = config.webico;
          document.getElementsByTagName('head')[0].appendChild(link);
          let webtitle = config['webtitle'] != '' ? config['webtitle'] : '自助发卡系统';
          document.title = webtitle;
          let utils = (this as any).$utils;
          // utils.checkBrowser(config);
          let id = this.$route.query.id;
          if(config.template_use == 1) {
              this.$router.push({ name: 'index', query: { id: id }});
          } else if(config.template_use == 2) {
              this.$router.push({ name: 'home', query: { id: id }});
          }
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
}
